import { createSignal, Show, createMemo } from "solid-js";
import { createAsync, RouteDefinition, useAction } from "@solidjs/router";
import { Meta } from "@solidjs/meta";
import { createForm } from "@felte/solid";
import * as z from "zod";
import { validator } from "@felte/validator-zod";
import { TextFieldInput } from "~/components/inputs";
import Body from "~/components/Body";
import Button from "~/components/Button";
import { Icon } from "solid-heroicons";
import { checkCircle, faceFrown } from "solid-heroicons/outline";
import { Turnstile } from "~/components/utility";
import { CoreValueCard, Odometer } from "~/components/cvc";
import { cvcNumToName } from "~/components/cvc/utils";
import { getCVCCounts, sendCVCAction } from "~/services/cvc";
import { useErrorContext } from "~/utils/contexts";
import { checkError } from "~/services/roma-api/errors";

export const route = {
  preload: () => {
    try {
      getCVCCounts();
    } catch (error) {
      if (import.meta.env.DEV) {
        console.log("[(cvc).tsx]: Error caught in createAsync: ", error);
      }
    }
  },
} satisfies RouteDefinition;

export default function CVCLanding() {
  const { addError } = useErrorContext();
  const [selectedCVC, setSelectedCVC] = createSignal();
  const [formStatus, setFormStatus] = createSignal<
    "ready" | "sending" | "submitted" | "error"
  >("ready");
  const MESSAGE_MAX_LENGTH = 350;
  const [remainingChar, setRemainingChar] = createSignal(MESSAGE_MAX_LENGTH);

  // * RESOURCE * //
  const cvcCounts = createAsync(async () => {
    try {
      const data = await getCVCCounts();
      if (Object.keys(data).length > 0) {
        const total = Object.values(data).reduce((count, curr) => count + curr);
        return { ...data, Total: total };
      }
      return data;
    } catch (error) {
      const err = checkError(error);
      if (import.meta.env.DEV) {
        console.log("[(cvc).tsx]: Error caught in createAsync: ", err);
        // TODO - Sentry
        // ? Not worth displaying error for this, just send report to sentry..
      }
    }
  });
  // * FN / ACTIONS //

  const sendCVC = useAction(sendCVCAction);

  const resetFormAndScrollToTop = () => {
    reset();
    setSelectedCVC(undefined);
    setFormStatus("ready");
    const section = document.getElementById("select-CVC");
    section?.scrollIntoView({ behavior: "smooth" });
  };

  const setToken = (token?: string) => {
    setData("Token", token);
  };

  const handleSelect = (e: Event) => {
    // @ts-ignore
    setSelectedCVC(e.currentTarget.value);
    setTimeout(() => {
      const section = document.getElementById("content");
      section?.scrollIntoView({ behavior: "smooth" });
    }, 400);
  };

  // * FORM * //

  const optionalEmail = z.union([z.literal(""), z.string().email()]);
  const schema = z.object({
    CoreValue: z.string(),
    ToEmail: z.string().email(),
    ToName: z
      .string()
      .trim()
      .min(1, { message: "Required" })
      .max(50, { message: "Max 50 characters." }),
    FromName: z.string().max(50, { message: "Max 50 characters." }).optional(),
    FromEmail: optionalEmail,
    Message: z
      .string()
      .max(MESSAGE_MAX_LENGTH, {
        message: `Max ${MESSAGE_MAX_LENGTH} characters.`,
      })
      .optional(),
    Token: z.string(),
  });

  const { form, data, errors, reset, interacted, setData } = createForm({
    extend: validator({ schema }),
    onSubmit: async (values): Promise<void> => {
      const formatted = {
        ...values,
        CoreValue: cvcNumToName[values.CoreValue],
      };

      setFormStatus("sending");

      try {
        const response = await sendCVC(formatted);
        if (response.ID) {
          setFormStatus("submitted");
          return;
        } else {
          // TODO - error handling
          throw new Error();
        }
      } catch (error) {
        const err = checkError(error);
        if (import.meta.env.DEV) {
          console.log(
            "[(cvc).tsx]: Error caught submitting CVC: ",
            JSON.stringify(formatted),
            err,
          );
        }
        addError(err, {
          showDetails: true,
          severity: "critical",
          title: "CVC Error",
          message:
            "An error occurred while sending your Core Value Card. Please try again. If this error persists, kindly contact customer support. We apologize for the inconvenience.",
        });
        setFormStatus("error");
      }
    },
  });
  form;

  const textFieldsInteracted = createMemo(() => {
    return Boolean(interacted() && interacted() !== "CoreValue");
  });

  return (
    <Body class="px-4">
      <section class="max-w-4xl my-8 mx-auto">
        <div id="select-CVC" class="flex flex-col gap-4 mb-8">
          <div class="flex flex-col sm:flex-row justify-between items-center">
            <h2 class="text-2xl sm:text-4xl font-bold">
              Send a Core Value Card!
            </h2>
            <Show when={cvcCounts() && cvcCounts()?.Total}>
              <div class="flex flex-col items-center sm:items-end sm:text-right">
                <Odometer
                  number={(cvcCounts()!.Total?.toString() || "0").padStart(
                    4,
                    "0",
                  )}
                  class="odometer-classic"
                  speed={1000}
                  size={40}
                />
                <p>CVC's sent and counting!</p>
              </div>
            </Show>
          </div>
          <p class="text-sm sm:text-base">
            Core Value Awareness Week is our biannual celebration dedicated to
            recognizing and appreciating our exceptional team members.
            Throughout this week, we utilize Core Value Cards to express
            gratitude and acknowledge the outstanding contributions of our
            remarkable team!
          </p>
          <p class="text-sm sm:text-base">
            Take a moment today to live in gratitude and send a CVC or 10 to
            someone who lives by our Core Values! Give gratitude and recognition
            where it is deserved! Make someone's day and put a smile on their
            face!
          </p>
          <p class="text-xl sm:text-3xl font-bold">Choose a CVC below:</p>
        </div>
      </section>
      <form
        use:form
        class="max-w-4xl mx-auto bg-roma-grey px-2 sm:px-6 py-4 my-8 rounded-xl shadow-md"
      >
        <div class="grid sm:grid-cols-2 md:grid-cols-2 gap-4 my-4">
          {Array.from({ length: 10 }, (_, i) => (
            <>
              <label for={`cvc-${i + 1}`} class="cursor-pointer group">
                <input
                  type="radio"
                  class="peer sr-only"
                  name="CoreValue"
                  id={`cvc-${i + 1}`}
                  value={i + 1}
                  onChange={handleSelect}
                  onClick={handleSelect}
                />
                <CoreValueCard
                  val={i + 1}
                  class={`opacity-[0.85] peer-checked:border-3 peer-checked:border-roma-blue peer-checked:opacity-100 group-hover:opacity-100 group-hover:scale-[1.05] md:group-hover:scale-[1.05] group-hover:z-10 transition-all`}
                />
              </label>
            </>
          ))}
        </div>
        <section id="content" class="my-8">
          <div class="flex flex-col gap-4">
            <h3 class="text-xl sm:text-3xl font-bold">Send a message!</h3>
            <p>
              Add the name and email of who you'd like to send your CVC to!
              Include a message and let them know what they mean to you!
            </p>
          </div>
          <Show
            when={selectedCVC()}
            fallback={
              <div class="bg-white rounded-md px-4 py-8 flex items-center justify-center my-4">
                <p>Select a CVC above to continue!</p>
              </div>
            }
          >
            <div class="grid sm:grid-cols-2 gap-4 my-4">
              <CoreValueCard
                val={selectedCVC() as string}
                toName={data().ToName as string | undefined}
                fromName={data().FromName as string | undefined}
                message={data().Message as string | undefined}
                flippable={true}
                forceFlip={textFieldsInteracted}
              />
              <div class="bg-white rounded-md p-3 flex flex-col gap-1">
                <TextFieldInput
                  name="ToName"
                  label="Recipient's Name *"
                  error={errors().ToName}
                  validationState={errors().ToName ? "invalid" : "valid"}
                  maxLength={50}
                  required
                />
                <TextFieldInput
                  name="ToEmail"
                  label="Recipient's Email *"
                  validationState={errors().ToEmail ? "invalid" : "valid"}
                  error={errors().ToEmail}
                  required
                />
                <TextFieldInput
                  name="FromName"
                  label="Your Name"
                  placeholder="Optional"
                />
                <TextFieldInput
                  name="FromEmail"
                  label="Your Email"
                  placeholder="Optional"
                  validationState={errors().FromEmail ? "invalid" : "valid"}
                  error={errors().FromEmail}
                />
                <TextFieldInput
                  name="Message"
                  type="textarea"
                  label={`Message ${
                    remainingChar() === MESSAGE_MAX_LENGTH
                      ? ""
                      : `(${remainingChar()} characters remaining)`
                  }`}
                  class="mb-auto"
                  placeholder="Optional"
                  onChange={(val) => {
                    setRemainingChar(MESSAGE_MAX_LENGTH - val?.length);
                  }}
                  validationState={errors().Message ? "invalid" : "valid"}
                  error={errors().Message}
                  maxLength={MESSAGE_MAX_LENGTH}
                />
                <Turnstile callback={setToken} />
                <Show when={errors().Token}>
                  <div class="bg-red-50 p-4 text-sm">
                    We could not verify your request, please contact an
                    administrator.
                  </div>
                </Show>
                <Button
                  type="submit"
                  rootClass="mt-4"
                  class="w-full "
                  disabled={["sending"].includes(formStatus()) || !data().Token}
                >
                  Send!
                </Button>
              </div>
              <Show when={formStatus() === "submitted"}>
                <div class="col-span-full p-4 bg-green-100 rounded-md flex flex-col gap-4 items-center">
                  <Icon
                    path={checkCircle}
                    class="w-10 h-10 text-success-green"
                    stroke-width={2}
                  />
                  <p class="text-center">
                    Your Core Value Card has been successfully sent!
                  </p>
                  <Button type="button" onClick={resetFormAndScrollToTop}>
                    <span class="px-4">Send Another CVC!</span>
                  </Button>
                </div>
              </Show>
              <Show when={formStatus() === "error"}>
                <div class="col-span-full p-4 bg-red-100 rounded-md flex flex-col gap-4 items-center">
                  <Icon
                    path={faceFrown}
                    class="w-10 h-10 text-red-600"
                    stroke-width={2}
                  />
                  <p class="text-center">
                    Oops! Something went wrong, please try again or check back
                    later.
                    <br />
                    We apologize for the inconvenience!
                  </p>

                  <Button type="button" onClick={resetFormAndScrollToTop}>
                    <span class="px-4">Reset and try again</span>
                  </Button>
                </div>
              </Show>
            </div>
          </Show>
        </section>
      </form>
    </Body>
  );
}
